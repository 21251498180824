.form_padding {
  padding: 50px 0px;
}
.form-control {
  height: 50px;
  margin-bottom: 15px;
}

.form_padding .custom_btn {
  padding: 7px 40px;
  font-size: 18px;
  margin: 0 auto;
  display: block;
}
.none-shodow {
  box-shadow: none !important;
}
.cursor-pointer {
  cursor: pointer;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #007bff;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  height: 50px;
  display: grid;
  place-items: center;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-width {
  width: 100px;
  height: 100px;
}

textarea.form-control {
  height: 10vh;
}